//react
import { useState } from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { Stack } from "@mui/material";
import SvgIcon from "@mui/material/SvgIcon";
//assets
import { ReactComponent as CalendarSvg } from "../../assets/date_pick/Calendar.svg";
//internal
import DateMenu from "./DateMenu";

//calendar icon from figma
const CalendarIcon = (props) => {
  return (
    <div style={{ display: "grid", placeItems: "center", ...props?.sx }}>
      <SvgIcon
        component={CalendarSvg}
        fontSize="small"
        sx={{ width: "18px", fill: "none", stroke: "#111" }}
      />
    </div>
  );
};

//top class wrapper for whole component (root component)
function DatePick(props) {
  const [open, setOpen] = useState(false);
  const [anchor, setAnchor] = useState(null);

  const handleClick = (e) => {
    setOpen((prev) => {
      return Boolean(e.currentTarget) ? true : false;
    });
    setAnchor(e.currentTarget);
  };
  const handleClose = (e) => {
    setOpen(false);
    setAnchor(null);
  };
  return (
    <Paper
      sx={{
        p: "12px 24px",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        borderRadius: "4px",
        border: "1px solid #E0E0E0",
        ...props.sx,
      }}
      elevation={0}
      onClick={handleClick}
    >
      <Stack
        direction="row"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CalendarIcon sx={{ marginRight: "10px" }} />
        <Typography
          component="span"
          sx={{
            fontSize: "18px",
            lineHeight: "23px",
            color: "#0F0F0F",
          }}
        >
          Filter by date
        </Typography>
      </Stack>
      <DateMenu
        anchor={anchor}
        open={open}
        handleClose={handleClose}
        setSearchKey={props.setSearchKey}
      />
    </Paper>
  );
}

export default DatePick;
