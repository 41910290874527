//react
import { useRef, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Backdrop from "@mui/material/Backdrop";
import { styled } from "@mui/material/styles";
import { Typography, Box } from "@mui/material";
//internal
import DatePickerWithOption from "../forms/FormComponents/DatePickerWithOptions";

//utils & helper
import {
  axiosConfig,
  httpErrorHandler,
  notificationsHandler,
} from "../../utils/helpers";
//3rd party
import { useSnackbar } from "notistack";
//hooks
import useAxios from "../../hooks/useAxios";

const CustomDialog = styled(Dialog)(({ theme }) => ({
  padding: "32px",
  "& .MuiDialogContent-root": {
    padding: "0",
    marginBottom: "56px",
  },
  "& .MuiDialogTitle-root": {
    textAlign: "left",
    padding: "0",
    marginBottom: "12px",
  },
  "& .MuiDialogContentText-root": {
    textAlign: "left",
  },
  "& .MuiDialogActions-root": {},
  "& .MuiDialog-paper": {
    padding: "32px",
    borderRadius: "12px",
  },
}));
function ActivateCreditConsent({
  isActivateCreditsOpen,
  setIsActivateCreditsOpen,
  creditId,
  supervisorId,
  setData,
  ...props
}) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isRequestProcessing, setIsRequestProcessing] = useState(false);
  //axios instance
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();

  const handleClose = (e) => setIsActivateCreditsOpen(false);
  const [validity, setValidity] = useState("");
  const activateCredits = async () => {
    try {
      setIsRequestProcessing(true);
      const dateParts = validity.split("-");
      const year = parseInt(dateParts[0]);
      const month = parseInt(dateParts[1]) - 1; // Month is 0-indexed in Date
      const day = parseInt(dateParts[2]);

      const midnightDate = new Date(year, month, day, 0, 0, 0, 0);

      const config = axiosConfig({
        method: "PUT",
        uri: "/accounts/manager/supervisors/activate-credits",
        data: {
          credit_id: creditId,
          user_id: supervisorId,
          validity: midnightDate
            ? new Date(midnightDate)?.toISOString()
            : undefined,
        },
      });

      const response = await axiosInstance.current({
        ...config,
      });

      setData((prev) => {
        return { ...prev, status: "completed", credit: response.data };
      });
      notificationsHandler(
        "success",
        "Credits Activated Successfully",
        enqueueSnackbar,
        closeSnackbar
      );

      setIsRequestProcessing(false);
      handleClose();
    } catch (error) {
      if (error && error.message !== "canceled") {
        setIsRequestProcessing(false);
        handleClose();
      }
      httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
    }
  };

  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        // backdropFilter: "blur(5px)",
        // background: "transparent",
      }}
      open={isActivateCreditsOpen}
      onClose={handleClose}
    >
      <CustomDialog open={isActivateCreditsOpen} onClose={handleClose}>
        <DialogTitle
          sx={{
            ".MuiDialogTitle-root": { textAlign: "center", display: "none" },
          }}
        >
          Set Credits Validity and Activate the Credits
        </DialogTitle>
        <DialogContent sx={{ textAlign: "center", overflow: "hidden" }}>
          <DialogContentText>
            Make sure that the payment is completed before setting Credits
            Validity. Once the validity is set and credits are activated, it
            cannot be changed/updated.
          </DialogContentText>
          <Box sx={{ "&&": { mt: "20px" } }}>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                color: "#6D6D6D",
                lineHeight: "20.83px",
                whiteSpace: "nowrap",
                mb: "12px",
              }}
              textAlign="left"
            >
              Credits Validity
            </Typography>
            <DatePickerWithOption
              disablePrevious={true}
              buttonPickerSx={{ borderLeft: "1px solid #bdbdbd" }}
              sx={{ border: "1px solid #bdbdbd" }}
              setCreditsValidity={setValidity}
            />
          </Box>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Typography
            onClick={handleClose}
            sx={{
              cursor: "pointer",
              fontWeight: "500",
              fontSize: "18px",
              lineHeight: "23px",

              color: "#959595",
            }}
          >
            Back
          </Typography>
          <Button
            variant="contained"
            onClick={(e) => activateCredits()}
            disableElevation
            disabled={isRequestProcessing}
            sx={{
              backgroundColor: "#00B448",
              ":hover": {
                backgroundColor: "#038537",
              },
            }}
            autoFocus
          >
            Activate Credits
          </Button>
        </DialogActions>
      </CustomDialog>
    </Backdrop>
  );
}

export default ActivateCreditConsent;
