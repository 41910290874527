import { useState } from "react";
//mui
import Paper from "@mui/material/Paper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

//handle any new message if you want to change the default one
function getSelected(selectedItem) {
  switch (selectedItem ? selectedItem.toLowerCase() : selectedItem) {
    case "stripe":
      return "Stripe";
    case "cheque":
      return "Cheque";
    case "demo":
      return "Demo";
    case "other":
      return "Other";
    case "accepted":
      return "Approved";
    case "sent":
      return "Received";
    case "completed":
      return "Completed";
    case "rejected":
      return "Rejected";
    case "all":
      return "Sort By Status";
    case null:
      return "Sort By Status";
    case "":
      return "Sort By Status";
    default:
      return selectedItem;
  }
}
function SortBy({ setSortBy, sortByValues, sx }) {
  //for sort by
  const [selected, setSelected] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleItemClick = (e, value) => {
    setSortBy(value);
    setSelected(value);
    handleClose();
  };
  const handleClose = (e, value) => {
    // setSelected(value);
    setAnchorEl((prev) => {
      return null;
    });
  };

  return (
    <>
      <Paper
        elevation={0}
        onClick={handleClick}
        sx={{
          border: "1px solid #E0E0E0",
          borderRadius: "8px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          // mr: "20px",

          cursor: "pointer",
          px: "24px",
          py: "14.5px",

          ...sx,
        }}
      >
        <Typography
          variant="p"
          sx={{
            fontWeight: "400",
            fontSize: "18px",
            lineHeight: "23px",
            textAlign: "center",
            display: "inline-flex",
            alignItems: "center",
            color: "#0F0F0F",
            fontFamily: "Inter, sans-serif",
          }}
        >
          {getSelected(selected)}
          {/* Sort By Status */}
        </Typography>
        <ExpandMoreIcon
          sx={{
            ml: "10px",
            transition: "all 0.5s ease",
            transform: open ? "rotate(180deg)" : "initial",
          }}
        />
      </Paper>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ minWidth: "196px" }}
      >
        {sortByValues.map((item, index) => {
          return (
            <MenuItem
              onClick={(e) => {
                handleItemClick(e, item.value);
              }}
              key={index}
              sx={{ minWidth: "196px" }}
            >
              {item.label}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}

export default SortBy;

SortBy.defaultProps = {
  setSortBy: () => {},
  sortByValues: [{ value: "Test", label: "Test" }],
};
