// libraries
import { useRef } from "react";

// material UI styles
import withStyles from "@mui/styles/withStyles";
//hooks
import useAxios from "../../hooks/useAxios";
// material UI components
import Toolbar from "@mui/material/Toolbar";
import DialogTitle from "@mui/material/DialogTitle";
import MuiDialogContent from "@mui/material/DialogContent";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Dialog, MenuItem, Stack } from "@mui/material";

//3rd party
import { useSnackbar } from "notistack";
import { axiosConfig, httpErrorHandler } from "../../utils/helpers";
//3rd party
import { Formik, Form } from "formik";
import * as yup from "yup";
import { FORM_VALIDATION } from "../../utils/formValidation";
// internal
import ButtonWrapper from "./FormComponents/Button";
import TextField from "../forms/FormComponents/TextField";

const LOCAL_FORM_VALIDATION = yup.object().shape({
  totalCredits: FORM_VALIDATION["numberOfCredits"],
  totalPrice: FORM_VALIDATION["totalPrice"],
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const payment = [
  {
    value: "STRIPE",
    label: "STRIPE",
  },
  {
    value: "CHEQUE",
    label: "CHEQUE",
  },
];

export default function EditCreditRequestForm(props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  //axios instance
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();
  const handleSubmit = async (totalCredits, totalPrice, paymentMode) => {
    try {
      const config = axiosConfig({
        method: "PATCH",
        uri: `/credits/request/manager/${props.id}/`,
        data: {
          payment_mode: paymentMode,
          added: totalCredits,
          price: totalPrice ? totalPrice * 100 : null,
        },
      });
      await axiosInstance.current({
        ...config,
      });
      props.setEditOpen(false);
    } catch (error) {
      httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
    }
  };
  const FORM_INITIAL_VALUES = {
    totalPrice:
      parseFloat(props?.data?.credit?.payment?.price / 100).toFixed(2) || 0,
    totalCredits: parseFloat(props?.data?.credit?.added).toFixed(0) || 0,
    paymentMode: props.data.credit.payment.mode || "",
  };

  return (
    <Dialog
      onClose={() => props.setEditOpen(false)}
      open={props.editOpen}
      fullWidth
    >
      <Formik
        initialValues={FORM_INITIAL_VALUES}
        validationSchema={LOCAL_FORM_VALIDATION}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          await handleSubmit(
            values?.totalCredits,
            values?.totalPrice,
            values?.paymentMode
          );
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, isValid, values }) => {
          return (
            <Form>
              <DialogTitle>
                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item xs={11}>
                      <Toolbar>
                        <Box width="100%">
                          <Typography
                            style={{
                              color: "#000000",
                              fontWeight: "500",
                              fontSize: "24px",
                              lineHeight: "31.25px",
                            }}
                            align="center"
                          >
                            Update Credit Request
                          </Typography>
                        </Box>
                      </Toolbar>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogTitle>
              <DialogContent>
                <Stack
                  direction="column"
                  paddingLeft="20px"
                  paddingRight="20px"
                  spacing={2}
                >
                  <Stack direction="column" spacing={1}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#6D6D6D",
                        lineHeight: "20.83px",
                      }}
                      textAlign="left"
                    >
                      Number of credits
                    </Typography>

                    <TextField
                      type="number"
                      name="totalCredits"
                      variant="filled"
                      placeholder="$"
                      InputProps={{
                        disableUnderline: true,
                        sx: { background: "transparent" },
                      }}
                      sx={{
                        background: "transparent",
                        "& input": {
                          borderRadius: "8px",
                          border: "1px solid #E0E0E0",
                          padding: "12px",
                          background: "#F6F6F6",
                        },
                      }}
                    />
                  </Stack>
                  <Stack direction="column" spacing={1}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        lineHeight: "20.83px",
                        color: "#6D6D6D",
                      }}
                      textAlign="left"
                    >
                      Total Price
                    </Typography>
                    <TextField
                      type="number"
                      name="totalPrice"
                      variant="filled"
                      placeholder="$"
                      InputProps={{
                        disableUnderline: true,
                        sx: {
                          background: "transparent",
                          ":hover": {
                            background: "transparent",
                          },
                        },
                      }}
                      sx={{
                        background: "transparent",
                        "& input": {
                          borderRadius: "8px",
                          border: "1px solid #E0E0E0",
                          padding: "12px",
                          background: "#F6F6F6",
                        },
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        lineHeight: "20.83px",
                        color: "#6D6D6D",
                      }}
                      textAlign="left"
                    >
                      Price per Credit: $
                      {values?.totalCredits &&
                        parseFloat(
                          parseInt(values?.totalPrice) /
                            parseInt(values?.totalCredits)
                        )?.toFixed(2)}
                    </Typography>
                  </Stack>

                  <Stack direction="column">
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#6D6D6D",
                        lineHeight: "20.83px",
                      }}
                      textAlign="left"
                    >
                      Mode of Payment
                    </Typography>
                    <TextField
                      select
                      sx={{
                        width: "100%",
                        mt: 1,
                      }}
                      InputProps={{
                        sx: {
                          background: "#F6F6F6",
                          borderRadius: "8px",
                          border: "1px solid #E0E0E0",
                        },
                      }}
                      name="paymentMode"
                    >
                      {payment.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Stack>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                  paddingLeft="20px"
                  paddingRight="20px"
                  sx={{ marginTop: "64px" }}
                >
                  <Typography
                    cursor="pointer"
                    style={{
                      height: "34px",
                      color: "#959595",
                      cursor: "pointer",
                    }}
                    onClick={() => props.setEditOpen(false)}
                  >
                    Cancel
                  </Typography>

                  <ButtonWrapper
                    disableElevation
                    sx={{
                      maxHeight: "53px",
                      borderRadius: "4px",
                      mt: 0,
                    }}
                    disabled={!isValid || isSubmitting}
                  >
                    Update Request
                  </ButtonWrapper>
                </Stack>
              </DialogContent>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
}
