//mui
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
//assets
import { useState, memo } from "react";
//internal
import ButtonPicker from "./ButtonPicker.js";
function ButtonDatePicker({ sx, buttonPickerSx, disablePrevious, ...props }) {
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [value, setValue] = useState("");

  let minDate = null;
  if (disablePrevious) {
    minDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        slotProps={{
          field: { setOpen: setIsPickerOpen, sx: buttonPickerSx },
        }}
        slots={{
          field: ButtonPicker,
          ...props.slots,
        }}
        sx={{ ...sx }}
        minDate={minDate}
        value={value}
        onChange={(value) => {
          const today = new Date();
          const selected = new Date(value);
          selected.setHours(today.getHours());
          selected.setMinutes(today.getMinutes());
          selected.setSeconds(today.getSeconds());
          setValue(value);
          props?.getCustomValidity(selected);
        }}
        open={isPickerOpen}
        onOpen={() => setIsPickerOpen(true)}
        onClose={() => setIsPickerOpen(false)}
      />
    </LocalizationProvider>
  );
}

export default memo(ButtonDatePicker);
