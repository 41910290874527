//react
import { useState, useEffect, useCallback, useRef } from "react";
//mui
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/system";
import Button from "@mui/material/Button";
//internal
import HorizontalCard from "../components/horizontal_card/HorizontalCard";
import Status from "../components/status/Status";
import ComponentLoader from "../components/loader/ComponentLoader";
//3rd party
import { useSnackbar } from "notistack";
import { format } from "date-fns";

import { Link } from "react-router-dom";
//utils and helpers
import { axiosConfig, httpErrorHandler } from "../utils/helpers";
import SortBy from "../components/sort_by/SortBy";

//assets
import coinSvg from "../assets/dashboard/coin.svg";
import dollarSvg from "../assets/dashboard/dollar.svg";
import Icon from "@mui/material/Icon";

//hooks
import useAxios from "../hooks/useAxios";
//icons
function CoinIcon(props) {
  return (
    <Icon {...props}>
      <img src={coinSvg} alt="custom-credit-icon" />
    </Icon>
  );
}
function DollarIcon(props) {
  return (
    <Icon {...props}>
      <img src={dollarSvg} alt="custom-credit-icon" />
    </Icon>
  );
}

//////////////custom col table cell for the table header cells//////////////
const ColTableCell = styled(TableCell)({
  fontFamily: "Inter, sans-serif",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "21px",
  /* identical to box height */
  color: "#888888",
  textAlign: "center",
  background: "#F1F1F1",
});

//////////////custom table cell fro the table body cells/////////////
const RowTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "21px",
  color: "#0F0F0F",
  textAlign: "center",
}));

const sortByValues = [
  { label: "All", value: "" },
  {
    label: "Accepted",
    value: "ACCEPTED",
  },
  {
    label: "Received", //receive
    value: "SENT",
  },
  {
    label: "Completed",
    value: "COMPLETED",
  },
  {
    label: "Rejected",
    value: "REJECTED",
  },
];

//main code

function Dashboard({ ...props }) {
  const cols = [
    "REQUEST ID",
    "REQUEST BY",
    "CREDITS REQUIRED",
    "REQUESTED ON",
    "STATUS",
  ];
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();
  const [creditsRequestData, setCreditsRequestData] = useState([]);
  const [managerData, setManagerData] = useState(null);
  const [isDataFetching, setIsDataFetching] = useState(false);
  const [error, setError] = useState(null);
  const [reload, setReload] = useState(false);
  const [sortBy, setSortBy] = useState(null);
  const [searching, setSearching] = useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  //function for fetching the data for the credits request
  const fetchCreditsData = useCallback(
    async ({ abortController }) => {
      try {
        if (sortBy !== null) {
          setSearching(true);
        } else {
          setIsDataFetching(true);
        }
        const config = axiosConfig({
          uri: `/credits/request/manager/`,
          method: "GET",

          params: { credit_request_status: sortBy, ordering: "-created_on" },
        });

        const response = await axiosInstance.current({
          ...config,
          signal: abortController.signal,
        });

        setCreditsRequestData(response.data.results);
        if (sortBy !== null) {
          setSearching(false);
        } else {
          setIsDataFetching(false);
        }
      } catch (error) {
        if (error && !error.message === "canceled") {
          setError(error);
          if (sortBy !== null) {
            setSearching(false);
          } else {
            setIsDataFetching(false);
          }
        }
        httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
      }
    },
    [sortBy, enqueueSnackbar, closeSnackbar]
  );
  //fetching manager info
  const fetchManagerInfo = useCallback(
    async ({ abortController }) => {
      try {
        setIsDataFetching(true);
        const config = axiosConfig({
          uri: `/accounts/manager/`,
          method: "GET",
        });
        const response = await axiosInstance.current({
          ...config,
          signal: abortController.signal,
        });
        setManagerData(response.data);
        setIsDataFetching(false);
      } catch (error) {
        if (error && !error.message === "canceled") {
          setIsDataFetching(false);
          setError(error);
        }
        httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
      }
    },
    [enqueueSnackbar, closeSnackbar]
  );
  //fetching credit req data
  useEffect(() => {
    const abortController = new AbortController();
    fetchCreditsData({ abortController });
    return () => abortController.abort();
  }, [fetchCreditsData, reload]);

  //fetching manager data
  useEffect(() => {
    const abortController = new AbortController();
    fetchManagerInfo({ abortController });
    return () => abortController.abort();
  }, [fetchManagerInfo, reload]);
  return (
    <Paper
      sx={{ background: "#F9F9F9", height: "100%", pt: "1rem" }}
      elevation={0}
      className="Dashboard-Page-Container"
    >
      {isDataFetching || error ? (
        <ComponentLoader
          loading={isDataFetching}
          error={error}
          sx={{ background: "transparent" }}
          retry={() => {
            setReload((prev) => {
              return !prev;
            });
          }}
        />
      ) : (
        <Grid container spacing={1} rowSpacing={7}>
          <Grid container item xs={12}>
            <Grid container item spacing={2} xs={4}>
              <Grid item xs={12}>
                <HorizontalCard
                  title="Total Credits Sold"
                  amount={managerData?.credits?.credits_sold}
                  Icon={<CoinIcon sx={{ width: "28px" }} />}
                />
              </Grid>
              <Grid item xs={12}>
                <HorizontalCard
                  title="Total Amount Generated"
                  amount={managerData?.credits?.amount_generated / 100 || 0}
                  Icon={<DollarIcon sx={{ width: "28px", display: "flex" }} />}
                />
              </Grid>
            </Grid>
            <Grid item xs></Grid>
            <Grid item xs={6}>
              <Paper
                elevation={0}
                sx={{
                  p: "2rem",
                  boxShadow: "0px 3px 20px 4px rgba(0, 0, 0, 0.04)",
                  borderRadius: "8px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Typography
                  sx={{
                    my: "auto",
                    width: "100%",
                    textAlign: "center",
                    position: "relative",
                    top: "50%",
                    transform: "translateY(-50%)",
                    fontWeight: "700",
                    fontSize: "28px",
                    lineHeight: "36px",
                  }}
                >
                  Upcoming feature
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Paper
              elevation={0}
              sx={{
                boxShadow: "0px 5px 29px rgba(112, 112, 112, 0.06)",
                borderRadius: "8px",
              }}
            >
              <Paper
                elevation={0}
                sx={{
                  px: "32px",
                  py: "24px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  component="span"
                  sx={{
                    fontWeight: "500",
                    lineHeight: "26px",
                    fontSize: "20px",
                    color: "#6D6D6D",
                  }}
                >
                  Recent Credits Request
                </Typography>
                <SortBy sortByValues={sortByValues} setSortBy={setSortBy} />
              </Paper>
              {/* Todo: Update this with table component used in other portals */}
              <TableContainer
                component={Paper}
                sx={{ boxShadow: "none", mb: "60px" }}
              >
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {cols.map((element, index) => {
                        return (
                          <ColTableCell key={index}>{element}</ColTableCell>
                        );
                      })}
                      <ColTableCell></ColTableCell>
                    </TableRow>
                  </TableHead>
                  {searching || error ? (
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={6}>
                          <ComponentLoader
                            loading={searching}
                            error={error}
                            sx={{ background: "transparent" }}
                            retry={() => {
                              setReload((prev) => {
                                return !prev;
                              });
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ) : (
                    <TableBody>
                      {creditsRequestData.length > 5
                        ? creditsRequestData
                            .slice(0, 4)
                            .map((element, index) => {
                              return (
                                <TableRow key={index}>
                                  <RowTableCell
                                    sx={{ height: "88px", p: "0" }}
                                    align="left"
                                    component="th"
                                  >
                                    {element.id}
                                  </RowTableCell>
                                  <RowTableCell
                                    sx={{ height: "88px", p: "0" }}
                                    align="left"
                                    component="th"
                                  >
                                    {element.supervisor.first_name}
                                  </RowTableCell>
                                  <RowTableCell
                                    sx={{ height: "88px", p: "0" }}
                                    align="left"
                                    component="th"
                                  >
                                    {parseFloat(element?.credit?.added).toFixed(
                                      2
                                    )}
                                  </RowTableCell>
                                  <RowTableCell
                                    sx={{ height: "88px", p: "0" }}
                                    align="left"
                                    component="th"
                                  >
                                    {format(
                                      new Date(element.created_on),
                                      "dd MMM yyyy"
                                    )}
                                  </RowTableCell>
                                  <RowTableCell
                                    sx={{ height: "88px", p: "0" }}
                                    align="left"
                                    component="th"
                                  >
                                    <Status status={element.status} />
                                  </RowTableCell>
                                  <RowTableCell sx={{ width: "200px" }}>
                                    <Link
                                      to={`/credits/requests/${element.id}`}
                                      style={{ textDecoration: "none" }}
                                    >
                                      <Button
                                        disableElevation
                                        variant="contained"
                                        sx={{
                                          background: "#2f7ec7",
                                          ":hover": {
                                            background: "#2465a2",
                                          },
                                        }}
                                      >
                                        View Request
                                      </Button>
                                    </Link>
                                  </RowTableCell>
                                </TableRow>
                              );
                            })
                        : creditsRequestData.map((element, index) => {
                            return (
                              <TableRow key={index}>
                                <RowTableCell
                                  sx={{ height: "88px", p: "0" }}
                                  align="left"
                                  component="th"
                                >
                                  {element.id}
                                </RowTableCell>
                                <RowTableCell
                                  sx={{ height: "88px", p: "0" }}
                                  align="left"
                                  component="th"
                                >
                                  {element.supervisor.first_name}
                                </RowTableCell>
                                <RowTableCell
                                  sx={{ height: "88px", p: "0" }}
                                  align="left"
                                  component="th"
                                >
                                  {parseFloat(element?.credit?.added).toFixed(
                                    2
                                  )}
                                </RowTableCell>
                                <RowTableCell
                                  sx={{ height: "88px", p: "0" }}
                                  align="left"
                                  component="th"
                                >
                                  {format(
                                    new Date(element.created_on),
                                    "dd/MM/yyyy"
                                  )}
                                </RowTableCell>
                                <RowTableCell
                                  sx={{ height: "88px", p: "0" }}
                                  align="left"
                                  component="th"
                                >
                                  <Status status={element.status} />
                                </RowTableCell>
                                <RowTableCell sx={{ width: "200px" }}>
                                  <Link
                                    to={`/credits/requests/${element.id}`}
                                    style={{ textDecoration: "none" }}
                                  >
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      sx={{
                                        background: "#2f7ec7",
                                        ":hover": {
                                          background: "#2465a2",
                                        },
                                      }}
                                    >
                                      View Request
                                    </Button>
                                  </Link>
                                </RowTableCell>
                              </TableRow>
                            );
                          })}
                      {creditsRequestData.length ? (
                        <TableRow
                          sx={{
                            cursor: "pointer",
                          }}
                        >
                          <TableCell align="center" colSpan={6}>
                            <Link
                              to="/credits"
                              style={{
                                width: "100%",
                                display: "block",
                                textDecoration: "none",
                              }}
                            >
                              <Typography
                                textAlign="center"
                                sx={{
                                  width: "100%",
                                  fontSize: "16px",
                                  fontWeight: "500",
                                  lineHeight: "21px",
                                  color: "#2f7ec7",
                                  ":hover": {
                                    color: "#2376a6",
                                  },
                                }}
                              >
                                View All
                              </Typography>
                            </Link>
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow>
                          <TableCell align="center" colSpan={6}>
                            <Typography
                              textAlign="center"
                              sx={{
                                width: "100%",
                                fontSize: "16px",
                                fontWeight: "500",
                                lineHeight: "21px",
                                color: "#2f7ec7",
                                ":hover": {
                                  color: "#2376a6",
                                },
                              }}
                            >
                              {creditsRequestData.length
                                ? "View All"
                                : "No Data Available"}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
}

export default Dashboard;
