//REact
import { useState, useRef } from "react";

//mui
import Menu from "@mui/material/Menu";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Stack, Typography } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import Radio from "@mui/material/Radio";
//custom
import { format } from "date-fns";
//internal
import { CutomDateSelector } from "./CustomDateSelector";
//utils & helpers
import { dateParser } from "../../utils/helpers";
//custom colored radio
const CustomRadio = styled(Radio)(({ theme }) => ({
  "&.Mui-checked": {
    color: "#2F7EC7",
  },
}));

//date difference calculator
const getDateDifference = (filterBy) => {
  let today = new Date();
  switch (filterBy) {
    case "this_month":
      return `${new Date(new Date().setDate(1))} - ${new Date(
        new Date().setDate(today.getDate())
      )}`;
    case "this_week":
      return `${new Date(new Date().setDate(today.getDate() - 7))} - ${new Date(
        new Date().setDate(today.getDate())
      )}`;
    case "this_year":
      return `${new Date(today.getFullYear(), 0, 1, 0, 0, 0)} - ${new Date()} `;
    case "last_15days":
      return `${new Date(
        new Date().setDate(today.getDate() - 15)
      )} - ${new Date(new Date().setDate(today.getDate()))}`;
    case "last_30days":
      return `${new Date(
        new Date().setDate(today.getDate() - 30)
      )} - ${new Date(new Date().setDate(today.getDate()))}`;
    case "last_60days":
      return `${new Date(
        new Date().setDate(today.getDate() - 60)
      )} - ${new Date(new Date().setDate(today.getDate()))}`;
    default:
      return "";
  }
};

//single date row with radio
function SingleMenuItem(props) {
  const radioRef = useRef(null);
  return (
    <>
      <MenuItem
        id={props.index}
        onClick={(e) => {
          props.radioOnClick(e, radioRef, getDateDifference(props.item.value));
          props.onClose();
        }}
      >
        <ListItemIcon>
          <FormControlLabel
            value={props.item.value}
            control={
              <CustomRadio
                inputRef={radioRef}
                checked={props.selected}
                id={`radio-${props.index}`}
              />
            }
          />
        </ListItemIcon>
        <ListItemText>
          <Stack direction="row">
            <Typography
              component="span"
              sx={{
                fontSize: "16px",
                lineHeight: "20px",
                color: " #344054",
                minWidth: "150px",
              }}
            >
              {props.item.label}
            </Typography>
            <Typography
              component="span"
              sx={{
                fontSize: "14px",
                lineHeight: "18px",

                color: "#667085",
              }}
            >
              {props.item.label === "All"
                ? ""
                : dateParser(
                    getDateDifference(props.item.value).split("-")[0]
                  ) +
                  "-" +
                  dateParser(getDateDifference(props.item.value).split("-")[1])}
            </Typography>
          </Stack>
        </ListItemText>
      </MenuItem>
      {props.index === 2 || props.index === 6 ? <Divider /> : <></>}
    </>
  );
}

export default function DateMenu(props) {
  const listText = [
    { value: "", label: "All" },
    {
      value: "this_month",
      label: "This Month",
    },
    {
      value: "this_week",
      label: "This Week",
    },
    {
      value: "this_year",
      label: "This Year",
    },
    {
      value: "last_15days",
      label: "Last 15 days",
    },
    {
      value: "last_30days",
      label: "Last 30 days",
    },
    {
      value: "last_60days",
      label: "Last 60 days",
    },
  ];
  const [checked, setChecked] = useState({
    value: null,
    id: null,
    checked: false,
    startDate: null,
    endDate: null,
  });

  const handleChange = (e, radioRef, date) => {
    setChecked((prevState) => {
      return {
        value: radioRef.current.value,
        id: radioRef.current.id,
        checked: !radioRef.current.checked,
        startDate:
          date !== "" ? new Date(date.split("-")[0]).toISOString() : null,
        endDate:
          date !== "" ? new Date(date.split("-")[1]).toISOString() : null,
      };
    });
    props.setSearchKey((prev) => {
      return {
        ...prev,
        created_on_from:
          date !== ""
            ? format(new Date(date.split("-")[0]), "yyyy-MM-dd")
            : null,
        created_on_to:
          date !== ""
            ? format(new Date(date.split("-")[1]), "yyyy-MM-dd")
            : null,
      };
    });
    //backend api call will come here
  };
  return (
    <Menu open={props.open} anchorEl={props.anchor} onClose={props.handleClose}>
      <MenuList>
        {listText.map((item, index) => {
          return checked.value === item.value ? (
            <SingleMenuItem
              item={item}
              index={index}
              key={index}
              selected={true}
              radioState={checked}
              radioOnClick={handleChange}
              onClose={props.handleClose}
              setSearchKey={props.setSearchKey}
            />
          ) : (
            <SingleMenuItem
              item={item}
              index={index}
              key={index}
              selected={false}
              radioState={checked}
              radioOnClick={handleChange}
              onClose={props.handleClose}
              setSearchKey={props.setSearchKey}
            />
          );
        })}
      </MenuList>
      <CutomDateSelector setSearchKey={props.setSearchKey} />
    </Menu>
  );
}
DateMenu.defaultProps = {
  open: false,
  anchor: null,
  handleClose: () => {},
  setSearchKey: () => {},
};
