import { useState } from "react";
//mui
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
//internal components
import ClickAwayAccordion from "./ClickAwayAccordion";
//forms
import AssignServiceForm from "../forms/assignServiceForm";
import EditServiceForm from "../forms/editServiceForm";
//icons
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
function PopUp(props) {
  const { title, isPopUpOpen, setIsPopUpOpen, InnerComponent } = props;

  const handleClose = (e) => {
    setIsPopUpOpen(false);
  };
  return (
    <Dialog
      onClose={handleClose}
      open={isPopUpOpen}
      fullWidth={true}
      maxWidth="sm"
      sx={{ ".MuiPaper-root": { borderRadius: "16px" } }}
    >
      <DialogTitle align="center">{title}</DialogTitle>
      <DialogContent>{InnerComponent}</DialogContent>
    </Dialog>
  );
}
function ServiceAccordionSection({
  title,
  tagLine,
  buttonTitle,
  servicesData,
  setServicesData,
  supervisorId,
  sx,
}) {
  const [popUpData, setPopUpData] = useState(null);
  const [isAssignPopUpOpen, setIsAssignPopUpOpen] = useState(false);
  const [isEditPopUpOpen, setIsEditPopUpOpen] = useState(false);
  // const [popUpTitle, setPopUpTitle] = useState("Title");
  const openEditPopUp = (e, data) => {
    setPopUpData(data);
    setIsEditPopUpOpen(true);
  };
  const closeEditPopUp = () => {
    setIsEditPopUpOpen(false);
  };
  const openAssignPopUp = (e, data) => {
    setPopUpData(data);
    setIsAssignPopUpOpen(true);
  };
  const closeAssignPopUp = () => {
    setIsAssignPopUpOpen(false);
  };

  return (
    <Paper
      elevation={0}
      sx={{
        width: "100%",
        padding: "32px",
        ...sx,
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={1}
        sx={{
          borderBottom: " 1px solid #EAEAEA",
          pb: "24px",
          mb: "24px",
        }}
      >
        <Box>
          <Typography fontSize="20px" fontWeight="500" color="#0F0F0F" mb="8px">
            {title}
          </Typography>
          <Typography fontSize="14px" fontWeight="500" color="#898989">
            {tagLine}
          </Typography>
        </Box>
        <Button
          sx={{
            background: "#2f7ec7",
            color: "#fff",
            ":hover": {
              background: "#286aa7",
            },
          }}
          onClick={(e) => {
            openAssignPopUp();
          }}
          startIcon={<AddOutlinedIcon />}
        >
          {buttonTitle}
        </Button>
      </Stack>
      {servicesData?.count ? (
        servicesData?.results?.map((data, ind) => {
          return (
            <ClickAwayAccordion
              data={data}
              key={ind}
              openEditPopUp={openEditPopUp}
              closeEditPopUp={closeEditPopUp}
            />
          );
        })
      ) : (
        <></>
      )}
      {/* Edit service pop up*/}
      <PopUp
        title="Edit Service"
        isPopUpOpen={isEditPopUpOpen}
        setIsPopUpOpen={setIsEditPopUpOpen}
        setData={setServicesData}
        popUpData={popUpData}
        InnerComponent={
          <EditServiceForm
            servicesData={servicesData}
            serviceData={popUpData}
            setServicesData={setServicesData}
            supervisorId={supervisorId}
            closePopUp={closeEditPopUp}
          />
        }
      >
        <EditServiceForm />
      </PopUp>
      {/* Assign service pop up */}
      <PopUp
        title="Assign Service"
        isPopUpOpen={isAssignPopUpOpen}
        setIsPopUpOpen={setIsAssignPopUpOpen}
        setData={setServicesData}
        popUpData={popUpData}
        InnerComponent={
          <AssignServiceForm
            serviceData={popUpData}
            servicesData={servicesData?.results}
            setServicesData={setServicesData}
            closePopUp={closeAssignPopUp}
            supervisorId={supervisorId}
          />
        }
      >
        <AssignServiceForm />
      </PopUp>
    </Paper>
  );
}

export default ServiceAccordionSection;

ServiceAccordionSection.defaultProps = {
  title: "Title",
  tagLine: "Your tagline will come here.",
  buttonTitle: "Assign Services",
  supervisorId: null,
};
