//react
import { useState, useCallback, useRef } from "react";
//utils and helper
import {
  axiosConfig,
  httpErrorHandler,
  notificationsHandler,
} from "../../utils/helpers";
//hooks
import useAxios from "../../hooks/useAxios";
//mui
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import SvgIcon from "@mui/material/SvgIcon";

//3rd party
import { useSnackbar } from "notistack";
//assets/
import { ReactComponent as CreditsSvg } from "../../assets/sidebar/credits-icon.svg";
//3rd party
import { Formik, Form } from "formik";
import * as yup from "yup";
import { FORM_VALIDATION } from "../../utils/formValidation";
// internal
import ButtonWrapper from "./FormComponents/Button";
import CustomTextField from "../forms/FormComponents/TextField";

const LOCAL_FORM_VALIDATION = yup.object().shape({
  currentPrice: FORM_VALIDATION["totalPrice"],
});

function EditServiceForm(props) {
  const { serviceData, supervisorId, setServicesData, closePopUp } = props;
  //axios instance
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();

  const FORM_INITIAL_VALUES = {
    currentPrice: serviceData?.price ? serviceData?.price : 0,
  };
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isloading, setIsLoading] = useState(false);

  const unAssignService = useCallback(async () => {
    setIsLoading(true);
    try {
      const config = axiosConfig({
        method: "POST",
        uri: "/services/unassign/",
        data: {
          service: serviceData?.service?.id ? serviceData.service.id : "",
          user: supervisorId ? supervisorId : null,
        },
      });
      const response = await axiosInstance.current({
        ...config,
      });

      notificationsHandler(
        "success",
        `${response.data.detail}`,
        enqueueSnackbar,
        closeSnackbar
      );
      //filter services here
      setServicesData((prevData) => {
        return {
          ...prevData,
          count: prevData?.count - 1,
          results: prevData?.results?.filter((service, index) => {
            return serviceData.id !== service.id;
          }),
        };
      });
      closePopUp();
    } catch (error) {
      if (error && !error.message === "canceled") {
        setIsLoading(false);
        closePopUp();
      }
      httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
    }
  }, [
    serviceData,
    supervisorId,
    enqueueSnackbar,
    closeSnackbar,
    closePopUp,

    setServicesData,
  ]);

  const updateService = useCallback(
    async (currentPrice) => {
      setIsLoading(true);
      try {
        const config = axiosConfig({
          method: "POST",
          uri: "/services/supervisor/change-price/",
          data: {
            service_id: serviceData?.service?.id
              ? serviceData.service.id
              : null,
            supervisor_user_id: supervisorId ? supervisorId : null,
            price: currentPrice,
          },
        });
        await axiosInstance.current({
          ...config,
        });
        notificationsHandler(
          "success",
          "Service Price Has Changed Successfully",
          enqueueSnackbar,
          closeSnackbar,
          "",
          10000
        );
        setServicesData((prevData) => {
          return {
            ...prevData,
            results: prevData?.results?.map((service, ind) => {
              if (service.service.id === serviceData?.service?.id) {
                return { ...service, price: currentPrice };
              }
              return service;
            }),
          };
        });
        closePopUp();
      } catch (error) {
        if (error && !error.message === "canceled") {
          setIsLoading(false);
          closePopUp();
        }

        httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
      }
    },
    [
      serviceData,
      supervisorId,
      enqueueSnackbar,
      closeSnackbar,
      setServicesData,
      closePopUp,
    ]
  );
  return (
    <Paper sx={{ ...props.sx }} elevation={0}>
      <Formik
        initialValues={FORM_INITIAL_VALUES}
        validationSchema={LOCAL_FORM_VALIDATION}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);

          await updateService(values?.currentPrice);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, errors, values, touched, isValid }) => {
          return (
            <Form>
              <Stack>
                <Box sx={{ mb: "28px" }}>
                  <InputLabel htmlFor="default-price" sx={{ mb: "12px" }}>
                    Service
                  </InputLabel>
                  <TextField
                    sx={{ background: "#F6F6F6", width: "100%" }}
                    defaultValue={
                      serviceData?.service?.name
                        ? serviceData?.service?.name.toUpperCase()
                        : ""
                    }
                    disabled
                  ></TextField>
                </Box>
                <Box sx={{ mb: "28px" }}>
                  <InputLabel htmlFor="default-price" sx={{ mb: "12px" }}>
                    Default Credits Consumption
                  </InputLabel>
                  <TextField
                    id="default-price"
                    defaultValue={
                      serviceData?.service?.default_price
                        ? serviceData?.service?.default_price
                        : ""
                    }
                    labelvariant="outlined"
                    sx={{ background: "#F6F6F6", width: "100%" }}
                    disabled
                  />
                </Box>
                <Box sx={{ mb: "48px" }}>
                  <InputLabel htmlFor="amount" sx={{ mb: "12px" }}>
                    Credit Consumption Rate
                  </InputLabel>

                  <CustomTextField
                    value={values?.currentPrice}
                    variant="outlined"
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon
                            component={CreditsSvg}
                            sx={{
                              color: "transparent",
                              "& path": { stroke: "#888888" },
                            }}
                          ></SvgIcon>
                        </InputAdornment>
                      ),
                      sx: {
                        background: "#f6f6f6",
                        ":hover": {
                          background: "#f6f6f6",
                        },
                      },
                    }}
                    sx={{
                      background: "transparent",
                      "& input": {
                        borderRadius: "8px",

                        padding: "16px",
                        background: "#F6F6F6",
                      },
                    }}
                    name="currentPrice"
                  />
                </Box>
                <Stack
                  direction="row"
                  justifyContent={"space-between"}
                  mb="10px"
                >
                  <Button
                    disabled={isloading}
                    variant="outlined"
                    sx={{
                      borderColor: "#6D6D6D",
                      color: "#6D6D6D",
                      px: "16px",
                      py: "12px",
                      ":hover": { background: "#57575708", color: "#000" },
                    }}
                    onClick={(e) => {
                      unAssignService();
                    }}
                  >
                    Unassign Service
                  </Button>

                  <ButtonWrapper
                    disableElevation
                    sx={{
                      mt: 0,
                      px: "16px",
                      py: "12px",
                      border: "0",
                      ":hover": { border: 0, background: "#1f5c94" },
                    }}
                    disabled={!isValid || isSubmitting}
                  >
                    Update Service
                  </ButtonWrapper>
                </Stack>
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </Paper>
  );
}

export default EditServiceForm;

EditServiceForm.defaultProps = {
  serviceData: null,
  setServiceData: () => {},
  supervisorId: null,
  servicesData: [],
};
