import { createTheme } from "@mui/material";

export const light = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#04273A",
    },
    secondary: {
      main: "#2CA9E3",
      light: "#E9F7FF",
    },
    info: {
      main: "#BDE7D9",
    },
    warning: {
      main: "#E30606",
    },
    success: {
      main: "#00B448",
    },
  },
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
  },
});
