import logo from "../../assets/logo/Neurobit PSG logo - Dark.svg";
import { Formik, Form } from "formik";
import * as yup from "yup";
import {
  Container,
  Paper,
  Typography,
  Stack,
  FormControl,
} from "@mui/material";
import ButtonWrapper from "./FormComponents/Button";
import TextField from "../forms/FormComponents/TextField";

const FORM_VALIDATION = yup.object().shape({
  email: yup
    .string()
    .email("Invalid Email Address")
    .required("Email is Required"),
});
const FORMIK_INITIAL_VALUES = {
  email: "",
};

export default function ForgotPasswordForm({ handleSubmit, loading }) {
  return (
    <Container
      maxWidth={false}
      style={{
        padding: "50px",
        backgroundColor: "#E5E5E5",
        height: "100vh",
      }}
    >
      <Stack justifyContent="center" alignItems="center">
        <Paper
          elevation={0}
          style={{
            width: "100%",
            maxWidth: "700px",
            padding: "64px 64px  200px 64px",
            borderRadius: "12px",
          }}
        >
          <Stack direction="column" spacing={2}>
            <Stack justifyContent="center" alignItems="center">
              <img src={logo} alt="logo" width={108} height={60} />
            </Stack>
            <Stack
              direction="column"
              spacing={2}
              py={5}
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Typography
                style={{
                  fontSize: "28px",
                  fontWeight: "500",
                  lineHeight: "36.46px",
                  letterSpacing: "0em",
                  color: "#0F0F0F",
                }}
              >
                Forgot Password
              </Typography>
              <Typography
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "26.04px",
                  letterSpacing: "0em",
                  color: "#8A8A8A",
                }}
              >
                Please enter your email address that you used to register. We
                will send a password reset link shortly.
              </Typography>
            </Stack>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={3}
            >
              <Stack sx={{ width: "100%" }} spacing={1}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#898989",
                  }}
                >
                  Email address
                </Typography>

                <Formik
                  initialValues={FORMIK_INITIAL_VALUES}
                  validationSchema={FORM_VALIDATION}
                  onSubmit={async (values, { setSubmitting }) => {
                    setSubmitting(true);
                    await handleSubmit(values.email);
                    setSubmitting(false);
                  }}
                >
                  {({ isSubmitting, isValid }) => (
                    <Form>
                      <FormControl fullWidth>
                        <TextField name="email" />
                      </FormControl>
                      <ButtonWrapper
                        fullWidth
                        disabled={!isValid || isSubmitting}
                      >
                        Submit
                      </ButtonWrapper>
                    </Form>
                  )}
                </Formik>
              </Stack>
            </Stack>
            <Stack direction="column">
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "400",
                  color: "#8A8A8A",
                  textAlign: "center",
                  lineHeight: "0.1em",
                  width: "100%",
                  marginTop: "72px",
                }}
              >
                If you need any help, contact{" "}
                <a
                  href="mailto:partners@neurobit.com"
                  style={{ color: "#2F7EC7", textDecoration: "none" }}
                >
                  Neurobit PSG Support
                </a>
              </Typography>
            </Stack>
          </Stack>
        </Paper>
      </Stack>
    </Container>
  );
}
