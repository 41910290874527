// material UI components
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

//react
import { useState, useRef } from "react";
//axios instance
import useAxios from "../../hooks/useAxios";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { FORM_VALIDATION } from "../../utils/formValidation";
// internal
import ButtonWrapper from "./FormComponents/Button";
import TextField from "../forms/FormComponents/TextField";

//libs
import { useSnackbar } from "notistack";
//utils & helpers
import { API_URL, axiosConfig, httpErrorHandler } from "../../utils/helpers";

const LOCAL_FORM_VALIDATION = yup.object().shape({
  totalPrice: FORM_VALIDATION["totalPrice"],
});

const inititalState = {
  accept: false,
  reject: false,
  update: false,
};

export default function ApproveRequestDialog(props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [submitting, setSubmitting] = useState(inititalState);
  const axiosInstance = useRef();

  axiosInstance.current = useAxios();
  const handleSubmit = async (price) => {
    setSubmitting({ ...submitting, accept: true });

    try {
      const config = axiosConfig({
        method: "PATCH",
        url: API_URL + `/credits/request/manager/${props.id}/`,
        data: {
          price: price ? price * 100 : null,
          status: "ACCEPTED",
        },
      });
      await axiosInstance.current({
        ...config,
      });
      props.setApproveOpen(false);
    } catch (error) {
      httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
    }

    setSubmitting({ ...submitting, accept: false });
  };

  const FORM_INITIAL_VALUES = {
    totalPrice: (props?.data?.credit?.payment?.price / 100)?.toFixed(2),
  };

  return (
    <Dialog
      onClose={(e) => props.setApproveOpen(false)}
      open={props.approveOpen}
      fullWidth
    >
      <Formik
        initialValues={FORM_INITIAL_VALUES}
        validationSchema={LOCAL_FORM_VALIDATION}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          await handleSubmit(values?.totalPrice);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, isValid, values }) => {
          return (
            <Form>
              <Stack
                direction="column"
                spacing={4}
                sx={{ padding: "32px 39px 12px 32px" }}
              >
                {/* title */}
                <Stack direction="column" spacing={2}>
                  <Typography
                    style={{
                      color: "#0F0F0F",
                      fontWeight: "500",
                      fontSize: "24px",
                      lineHeight: "31.25px",
                    }}
                  >
                    Are you sure you want to approve this credit request?
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "400",
                      color: "#959595",
                      lineHeight: "23.44px",
                    }}
                    align="left"
                  >
                    Note: Once the credit request is approved, the supervisor
                    will be able to proceed with the payment.
                  </Typography>
                </Stack>
                {/* content */}
                <Box width="100%">
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#6D6D6D",
                      lineHeight: "20.83px",
                      whiteSpace: "nowrap",
                      mb: "12px",
                    }}
                    textAlign="left"
                  >
                    Total Price of the Credit Block{" "}
                  </Typography>

                  <TextField
                    type="number"
                    name="totalPrice"
                    variant="filled"
                    placeholder="$"
                    autoFocus
                    InputProps={{
                      disableUnderline: true,
                      sx: { background: "transparent" },
                    }}
                    sx={{
                      background: "transparent",
                      "& input": {
                        borderRadius: "8px",
                        border: "1px solid #E0E0E0",
                        padding: "12px",
                        background: "#F6F6F6",
                      },
                    }}
                  />

                  <Typography
                    sx={{
                      fontSize: "14px",
                      lineHeight: "18px",
                      fontWeight: "500",
                      color: "#0F0F0F",
                      whiteSpace: "nowrap",
                      mt: 1,
                    }}
                    textAlign="left"
                  >
                    Price per Credit: $
                    {values?.totalPrice &&
                      props?.data?.credit?.added &&
                      parseFloat(
                        values?.totalPrice / props?.data?.credit?.added
                      ).toFixed(2)}
                  </Typography>
                </Box>
              </Stack>
              {/* controls */}
              <Stack
                direction="column"
                spacing={4}
                sx={{ marginTop: "72px", padding: "32px" }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    cursor="pointer"
                    style={{
                      height: "34px",
                      color: "#959595",
                      cursor: "pointer",
                      whiteSpace: "nowrap",
                    }}
                    onClick={() => props.setApproveOpen(false)}
                  >
                    Back
                  </Typography>

                  <ButtonWrapper
                    disableElevation
                    sx={{
                      backgroundColor: "#00B448",
                      ":hover": {
                        backgroundColor: "#039c40",
                      },
                      p: "6px 16px",
                      mt: 0,
                    }}
                    disabled={!isValid || isSubmitting}
                  >
                    Approve Request
                  </ButtonWrapper>
                </Stack>
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
}
