//mui
import IconButton from "@mui/material/IconButton";
//internal
import SvgIcon from "@mui/material/SvgIcon";
//assets
import { ReactComponent as CalendarIcon } from "../../../../assets/date_pick/Calendar.svg";

function ButtonPicker({ ...props }) {
  const {
    setOpen,
    id,
    disabled,
    InputProps: { ref } = {},
    inputProps: { "aria-label": ariaLabel } = {},
  } = props;

  return (
    <IconButton
      sx={{
        borderRadius: 0,
        minHeight: "56px",
        px: "18px",
        py: "17px",
        borderLeft: "1px solid #E0E0E0",
        background: "#f6f6f6",
        ...props?.sx,
      }}
      id={id}
      disabled={disabled}
      ref={ref}
      aria-label={ariaLabel}
      onClick={() => setOpen(true)}
    >
      <SvgIcon
        component={CalendarIcon}
        fontSize="small"
        sx={{ width: "18px", fill: "none", stroke: "#888" }}
      />
    </IconButton>
  );
}

export default ButtonPicker;
