//mui
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
//react
import { useEffect, useState } from "react";
//utils
import { dateParser } from "../../../../utils/helpers";
//internal
import ButtonDatePicker from "../DatePicker";

//date difference calculator
const getDateDifference = (monthCount, customEndValue = null) => {
  let today = new Date();

  switch (String(monthCount)) {
    case "1":
      return [
        new Date(),
        new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          today.getDate() - 1
        ),
      ];
    case "6":
      return [
        new Date(),
        new Date(
          today.getFullYear(),
          today.getMonth() + 6,
          today.getDate() - 1
        ),
      ];

    case "12":
      return [
        new Date(),
        new Date(
          today.getFullYear(),
          today.getMonth() + 12,
          today.getDate() - 1
        ),
      ];
    case "18":
      return [
        new Date(),
        new Date(
          today.getFullYear(),
          today.getMonth() + 18,
          today.getDate() - 1
        ),
      ];
    case "24":
      return [
        new Date(),
        new Date(
          today.getFullYear(),
          today.getMonth() + 24,
          today.getDate() - 1
        ),
      ];
    case "custom":
      if (customEndValue) {
        return [
          new Date(),
          new Date(
            customEndValue?.getFullYear(),
            customEndValue?.getMonth(),
            customEndValue?.getDate()
          ),
        ];
      }
      return [
        new Date(),
        new Date(today.getFullYear(), today.getMonth() + 1, today.getDate()),
      ];
    default:
      return [
        new Date(),
        new Date(
          today.getFullYear(),
          today.getMonth() + 12,
          today.getDate() - 1
        ),
      ];
  }
};

const getDateWithoutTime = (dateobject) => {
  if (dateobject instanceof Date && !isNaN(dateobject))
    return `${dateobject?.getFullYear()}-${(dateobject.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${dateobject.getDate()}`;
  return null;
};

function Index({
  sx,
  buttonPickerSx,
  setCreditsValidity,
  disablePrevious,
  ...props
}) {
  const [validity, setValidity] = useState("");
  const [customValidity, setCustomValidity] = useState(null);
  const handleChange = (event) => {
    setValidity(event.target.value);
    setCreditsValidity(event.target.value);
  };
  const getCustomValidity = (validity) => {
    setCustomValidity(validity);
  };

  const [monthsMapping, setMonthsMapping] = useState([
    { label: "1 Month", monthCount: 1, custom: false },
    { label: "6 Month", monthCount: 6, custom: false },
    { label: "12 Month", monthCount: 12, custom: false },
    { label: "18 Month", monthCount: 18, custom: false },
    { label: "24 Month", monthCount: 24, custom: false },
  ]);

  useEffect(() => {
    if (
      customValidity &&
      !monthsMapping.find((mapping) => {
        return mapping?.label.toLocaleLowerCase() === "custom";
      })
    ) {
      setMonthsMapping((prev) => {
        return [
          ...prev,
          { label: "Custom", monthCount: "custom", custom: true },
        ];
      });

      setValidity(
        getDateWithoutTime(getDateDifference("custom", customValidity)[1])
      );
      setCreditsValidity(
        getDateWithoutTime(getDateDifference("custom", customValidity)[1])
      );
    } else if (customValidity) {
      setValidity(
        getDateWithoutTime(getDateDifference("custom", customValidity)[1], true)
      );
      setCreditsValidity(
        getDateWithoutTime(getDateDifference("custom", customValidity)[1], true)
      );
    }
  }, [customValidity, monthsMapping, setCreditsValidity]);

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{
        border: "1px solid #E0E0E0",
        borderRadius: "4px",
        ...sx,
      }}
    >
      <FormControl fullWidth>
        <Select
          value={validity}
          onChange={handleChange}
          sx={{
            maxHeight: "56px",
            background: "#f6f6f6",
            border: 0,
            outline: 0,
            "& > fieldset": {
              border: 0,
            },
          }}
        >
          {monthsMapping?.map((mapping, index) => {
            return (
              <MenuItem
                value={
                  mapping?.label.toLocaleLowerCase() !== "custom"
                    ? getDateWithoutTime(
                        getDateDifference(mapping?.monthCount)[1]
                      )
                    : getDateWithoutTime(
                        getDateDifference(
                          mapping?.monthCount,
                          customValidity
                        )[1]
                      )
                }
                key={`${index}`}
                sx={{ py: "12px" }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                >
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        lineHeight: "20px",
                        color: "#344054",
                      }}
                    >
                      {mapping?.label}
                    </Typography>
                  </Stack>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      lineHeight: "18px",
                      color: "#667085",
                    }}
                  >
                    {mapping?.label.toLocaleLowerCase() === "custom"
                      ? dateParser(
                          getDateDifference(
                            mapping?.monthCount,
                            customValidity
                          )[0],
                          false
                        ) +
                        " - " +
                        dateParser(
                          getDateDifference(
                            mapping?.monthCount,
                            customValidity
                          )[1],
                          true
                        )
                      : dateParser(getDateDifference(mapping?.monthCount)[0]) +
                        " - " +
                        dateParser(
                          getDateDifference(mapping?.monthCount)[1],
                          true
                        )}
                  </Typography>
                </Stack>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <ButtonDatePicker
        getCustomValidity={getCustomValidity}
        buttonPickerSx={buttonPickerSx}
        disablePrevious={disablePrevious}
      />
    </Stack>
  );
}

Index.defaultProps = {
  disablePrevious: true,
};
export default Index;
