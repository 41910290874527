//react
import { useState, useEffect, useCallback, useRef } from "react";
//utils and helper
import {
  axiosConfig,
  httpErrorHandler,
  notificationsHandler,
} from "../../utils/helpers";
//hooks
import useAxios from "../../hooks/useAxios";
//mui
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
// import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import SvgIcon from "@mui/material/SvgIcon";
//assets/
import { ReactComponent as CreditsSvg } from "../../assets/sidebar/credits-icon.svg";
//3rd party
import { useSnackbar } from "notistack";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { FORM_VALIDATION } from "../../utils/formValidation";
// internal

import Select from "../forms/FormComponents/Select";
import ButtonWrapper from "./FormComponents/Button";
import TextField from "../forms/FormComponents/TextField";

const LOCAL_FORM_VALIDATION = yup.object().shape({
  consumptionRate: FORM_VALIDATION["totalPrice"],
  selectedService: FORM_VALIDATION["selectedService"],
});

export default function AssignServiceForm(props) {
  const { closePopUp, setServicesData, servicesData } = props;
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [services, setServices] = useState([]);
  const [serviceSelected, setServiceSelected] = useState(null);

  const assignService = useCallback(
    async (currentPrice) => {
      const getData = () => {
        if (currentPrice) {
          return {
            service: serviceSelected?.id ? serviceSelected.id : null,
            user: props.supervisorId ? props.supervisorId : null,
            price: currentPrice,
          };
        }
        return {
          service: serviceSelected?.id ? serviceSelected.id : null,
          user: props.supervisorId ? props.supervisorId : null,
        };
      };

      try {
        const config = axiosConfig({
          method: "POST",
          uri: "/services/assign/",
          data: getData(),
        });
        const response = await axiosInstance.current({
          ...config,
        });
        notificationsHandler(
          "success",
          `${response?.data?.detail}`,
          enqueueSnackbar,
          closeSnackbar
        );
        setServicesData((prevServices) => {
          return {
            ...prevServices,
            count: prevServices?.count + 1,
            results: [...prevServices?.results, response.data.data],
          };
        });
      } catch (error) {
        if (error && !error.message === "canceled")
          httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
      }
      closePopUp();
    },
    [
      serviceSelected,
      props,
      enqueueSnackbar,
      closeSnackbar,
      setServicesData,
      closePopUp,
    ]
  );

  const FORM_INITIAL_VALUES = {
    consumptionRate: 0,
    defaultRate: serviceSelected?.default_price
      ? serviceSelected?.default_price
      : 0,
    selectedService: "",
  };
  useEffect(() => {
    const abortController = new AbortController();

    (async () => {
      try {
        const config = axiosConfig({
          method: "GET",
          uri: "/services/",
        });
        const response = await axiosInstance.current({
          ...config,
          signal: abortController.signal,
        });
        setServices(
          response.data?.results?.filter((service) => {
            return !servicesData?.find((assignedService, index) => {
              return service.id === assignedService.service.id;
            });
          })
        );
      } catch (error) {
        if (error && !error.message === "canceled")
          httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
      }
    })();
    return () => {
      return abortController.abort();
    };
  }, [enqueueSnackbar, closeSnackbar, servicesData]);

  return (
    <Paper sx={{ ...props.sx }} elevation={0}>
      <Formik
        initialValues={FORM_INITIAL_VALUES}
        validationSchema={LOCAL_FORM_VALIDATION}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          await assignService(values?.consumptionRate);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, isValid, values, errors, setFieldValue }) => {
          return (
            <Form>
              <Stack>
                <Box sx={{ mb: "28px" }}>
                  <InputLabel htmlFor="default-price" sx={{ mb: "12px" }}>
                    Select a Serivce*
                  </InputLabel>
                  <Select
                    onChange={(e) => {
                      setFieldValue("selectedService", e.target.value, true);
                      setServiceSelected(() => {
                        const service = services.find((service, index) => {
                          return (
                            service.name.toLowerCase() ===
                            e.target.value.toLowerCase()
                          );
                        });
                        setFieldValue(
                          "defaultRate",
                          service?.default_price ? service?.default_price : 0,
                          true
                        );
                        return service;
                      });
                    }}
                    value={values?.selectedService}
                    name="selectedService"
                    fullWidth
                  >
                    {services.length ? (
                      services.map((val, ind) => {
                        return (
                          <MenuItem
                            key={ind}
                            value={val?.name ? val?.name : ""}
                            id={val.id}
                          >
                            {val?.name ? val?.name.toUpperCase() : ""}
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem value={""} id={0}>
                        {"No Services Found"}
                      </MenuItem>
                    )}
                  </Select>
                </Box>
                <Box sx={{ mb: "28px" }}>
                  <InputLabel htmlFor="default-price" sx={{ mb: "12px" }}>
                    Default Credits Consumption
                  </InputLabel>

                  <TextField
                    disabled
                    name="defaultRate"
                    value={values?.defaultRate}
                    type="text"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon
                            component={CreditsSvg}
                            sx={{
                              color: "transparent",
                              "& path": { stroke: "#888888" },
                            }}
                          ></SvgIcon>
                        </InputAdornment>
                      ),

                      sx: {
                        background: "#f6f6f6",
                        ":hover": {
                          background: "#f6f6f6",
                        },
                      },
                    }}
                    sx={{
                      background: "transparent",
                      "& input": {
                        borderRadius: "8px",

                        padding: "16px",
                        background: "#F6F6F6",
                      },
                    }}
                  ></TextField>
                </Box>
                <Box sx={{ mb: "28px" }}>
                  <InputLabel htmlFor="amount" sx={{ mb: "12px" }}>
                    Credit Consumption Rate
                  </InputLabel>
                  <TextField
                    name="consumptionRate"
                    type="text"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon
                            component={CreditsSvg}
                            sx={{
                              color: "transparent",
                              "& path": { stroke: "#888888" },
                            }}
                          ></SvgIcon>
                        </InputAdornment>
                      ),

                      sx: {
                        background: "#f6f6f6",
                        ":hover": {
                          background: "#f6f6f6",
                        },
                      },
                    }}
                    sx={{
                      background: "transparent",
                      "& input": {
                        borderRadius: "8px",

                        padding: "16px",
                        background: "#F6F6F6",
                      },
                    }}
                  ></TextField>
                </Box>
                <Stack
                  direction="row"
                  justifyContent={"space-between"}
                  mb="10px"
                >
                  <Button
                    variant="text"
                    sx={{
                      color: "#6D6D6D",
                      px: "16px",
                      py: "12px",
                    }}
                    onClick={(e) => props.closePopUp()}
                  >
                    Cancel
                  </Button>

                  <ButtonWrapper
                    disabled={!isValid || isSubmitting}
                    disableElevation
                    sx={{ mt: 0 }}
                  >
                    Assign Service
                  </ButtonWrapper>
                </Stack>
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </Paper>
  );
}

AssignServiceForm.defaultProps = {
  sx: {},
  supervisorId: null,
  closePopUp: () => {},
};
