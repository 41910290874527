// custom
import logo from "../../assets/logo/Neurobit PSG logo - Dark.svg";

import {
  Container,
  Paper,
  Typography,
  Stack,
  FormControl,
} from "@mui/material";
// internal

import ButtonWrapper from "./FormComponents/Button";
import TextField from "../forms/FormComponents/TextField";
//utils and helper
import urlpatterns from "../../utils/urls.json";
//3rd party
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as yup from "yup";
//form validators
import { FORM_VALIDATION } from "../../utils/formValidation";
import useAuth from "../../hooks/useAuth";

const LOCAL_FORM_VALIDATION = yup.object().shape({
  email: FORM_VALIDATION["email"],
  password: FORM_VALIDATION["password1"],
});
const FORM_INITIAL_VALUES = {
  email: "",
  password: "",
};
export default function SigninForm(props) {
  const [, dispatch] = useAuth();
  return (
    <Container
      maxWidth={false}
      style={{
        padding: "50px",
        backgroundColor: "#F9F9F9",
        minHeight: "100vh",
        height: "100%",
      }}
    >
      <Stack justifyContent="center" alignItems="center">
        <Paper
          elevation={0}
          style={{
            width: "100%",
            maxWidth: "768px",
            padding: "48px 64px 60px 64px",
            borderRadius: "12px",
            boxShadow: "0px 5px 29px rgba(112, 112, 112, 0.06)",
          }}
        >
          <Stack direction="column">
            <Stack justifyContent="center" alignItems="center">
              <img src={logo} alt="logo" width={108} height={60} />
            </Stack>
            <Stack
              direction="column"
              spacing={2}
              py={5}
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Typography
                style={{
                  fontSize: "28px",
                  fontWeight: "500",
                  lineHeight: "36.46px",
                  letterSpacing: "0em",
                  color: "#0F0F0F",
                }}
              >
                Login to account{" "}
              </Typography>
            </Stack>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              // spacing={2}
            >
              <Stack sx={{ width: "100%" }} spacing={1}>
                <Formik
                  initialValues={FORM_INITIAL_VALUES}
                  validationSchema={LOCAL_FORM_VALIDATION}
                  onSubmit={async (values, { setSubmitting }) => {
                    setSubmitting(true);
                    const response = await props.handleSubmit(
                      values?.email,
                      values?.password
                    );
                    if (response?.status === 200) {
                      dispatch({
                        type: "signin",
                        payload: {
                          user: response.data?.user,
                          accessToken: {
                            token: response.data?.access_token,
                            expiration: response.data?.access_token_expiration,
                          },
                          refreshToken: {
                            token: response.data?.refresh_token,
                            expiration: response.data?.refresh_token_expiration,
                          },
                        },
                        error: null,
                      });
                    }

                    setSubmitting(false);
                  }}
                >
                  {({ isSubmitting, isValid }) => {
                    return (
                      <Form>
                        <Stack my={2} direction="column" spacing={3}>
                          <FormControl>
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: "400",
                                color: "#898989",
                              }}
                            >
                              Email address
                            </Typography>
                            <TextField
                              inputProps={{
                                style: {
                                  padding: "15px",
                                  width: "100%",
                                  fontSize: "16px",
                                  color: "#0F0F0F",
                                  backgroundColor: "#FBFBFB",
                                  fontFamily: "Inter, sans-serif",
                                },
                              }}
                              type="email"
                              name="email"
                              sx={{
                                borderRadius: "5px",
                                borderColor: "1px solid #C4C4C4",
                                width: "100%",
                              }}
                            />
                          </FormControl>

                          <FormControl>
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: "400",
                                color: "#898989",
                              }}
                            >
                              Password
                            </Typography>
                            <TextField
                              name="password"
                              inputProps={{
                                style: {
                                  padding: "15px",
                                  fontSize: "16px",
                                  color: "#0F0F0F",
                                  fontFamily: "Inter sans-serif",
                                  backgroundColor: "#FBFBFB",
                                },
                              }}
                              type="password"
                              sx={{
                                borderRadius: "5px",
                                borderColor: "1px solid #C4C4C4",
                                width: "100%",
                              }}
                            />
                          </FormControl>
                        </Stack>
                        <Stack direction="column" spacing={5} py={2}>
                          <Typography
                            component={Link}
                            to={urlpatterns.forgot_password}
                            sx={{
                              width: "max-content",
                              marginLeft: "auto",
                              cursor: "pointer",
                              fontSize: "16px",
                              fontWeight: "400",
                              lineHeight: "19px",
                              letterSpacing: "0em",
                              textAlign: "right",
                              color: "#8A8A8A",
                            }}
                          >
                            Forgot Password?
                          </Typography>

                          <ButtonWrapper
                            disableElevation
                            type="submit"
                            variant="contained"
                            sx={{
                              backgroundColor: "#2F7EC7",
                              borderRadius: "5px",
                              padding: "15px",
                            }}
                            disabled={!isValid || isSubmitting}
                          >
                            LOG IN
                          </ButtonWrapper>
                        </Stack>
                      </Form>
                    );
                  }}
                </Formik>
              </Stack>
            </Stack>
          </Stack>
        </Paper>
      </Stack>
    </Container>
  );
}
